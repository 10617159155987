import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'isFirstPage',
    // redirect: '/firstpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/FirstPage.vue')
  },
  {
    path: '/firstpage',
    name: 'FirstPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/FirstPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: '/',
  routes
})

export default router
