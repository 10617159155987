<template>
  <div id="app" ref="cont">
      <router-view/>
  </div>
</template>
<script>
  // import axios from 'axios'
  export default {
    name: 'App',
    components: {
    },
    data(){
      return {
  
        width: window.innerWidth,
        height: window.innerHeight, //window.innerHeight,
      }
    }, 
    mounted(){},
    methods: {
      
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
  // max-width: 2285px;
  // height: 100vh;
}

:root {
  --base_color: #FB4869;
  --base_width: 1184px;
  --base_black: #505361;
  --base_hover: #5e80f8;
  --base_active: #2a47af;
}

#app,
html,
body {
  width: 100%;
  position: relative;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  background: #fff;
  padding: 0px;
  margin: 0px;
  min-width: var(--base_width);
}
</style>
