export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return {
                    clickBtuuonRepeat: false
                }
            },
            methods:{

                //将url转成对象
                parseQueryString(url) {
                    //url参数转对象
                    url = !url ? window.location.href : url
                    if (url.indexOf('?') === -1) {
                    return null
                    }
                    let search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)
                    if (search === '') {
                    return null
                    }
                    search = search.split('&')
                    let query = {}
                    for (let i = 0; i < search.length; i++) {
                    let pair = search[i].split('=')
                    // query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
                    query[pair[0]] = pair[1] || ''
                    }
                    return query
                },

                isToPdf(){
                    let difference = ''
                    let res = this.parseQueryString(window.location.href)
                    if(res && res.bd_vid){
                        difference = 'bd_vid'
                    } else if(res && res.qhclickid){
                        difference = 'qhclickid'
                    }

                    //组织给后台参数
                    let par = {
                        tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id'),
                        tg_type: sessionStorage.getItem('tg_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_type')),
                        cid: sessionStorage.getItem('pdf_id') == 'null' ? 0 : Number(sessionStorage.getItem('pdf_id')), //渠道号
                        tg_url_type: sessionStorage.getItem('tg_url_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_url_type')),
                        url: window.location.host
                    }

                    window.open(`http://${window.location.host}/convert/conversion/PDF2Word?id=${par.cid}&${difference == 'bd_vid' ? 'bd_vid' : 'qhclickid'}=${par.tg_id}`)
                }
            }
        })
    }
}